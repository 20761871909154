.customBorder th,
.customBorder td,
.customBorder {
    border: 1px solid black !important;
}

.customBorder div {
    border: 1px solid black !important;
}

.customBorder table {
    border-collapse: collapse !important;
}

/* For Table Header */
.customTable thead {
    background-color: #2A7FD4; /* Blue color for table header */
    color: white;
}

.customTable th, .customTable td {
    border: 1px solid black; /* Add borders to all table cells */
    padding: 8px;
}

.customTable tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Light gray for even rows */
}

.customTable tbody tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
}

/* For Customer TRN Section */
.bgLightBlue {
    background-color: #A6C8FF !important;
    color: black !important;
    font-weight: bold;
    padding: 3px 1px; /* 3px for top and bottom, 1px for left and right */
}

/* @media print {
    .customTable thead {
        background-color: #2A7FD4 !important;
        color: rgb(0, 0, 0) !important;
    }

    .bgLightBlue {
        background-color: #A6C8FF !important;
        color: rgb(0, 0, 0) !important;
    }
} */

@media print {
    .customTable thead {
      background-color: #2A7FD4 !important;
      color: rgb(0, 0, 0) !important;
      -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
      print-color-adjust: exact !important;         /* Firefox and others */
    }
  
    .bgLightBlue {
      background-color: #A6C8FF !important;
      color: black !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }

    .tableHeader th {
        background-color: #2A7FD4 !important;
        color: white !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    .black-text {
        color: black !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }      
  
  }
  
